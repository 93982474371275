import api from 'api/client';
import { z } from 'zod';
import { dateSchema } from './shared/common.schema';
import { therapyCourseSchema } from './therapy';

const userEndpoint = `user/me`;
const userByIdEndpoint = `user`;

export enum PaymentMethod {
  AppStore = 'AppStore',
  Stripe = 'Stripe',
}

export enum SubscriptionStatus {
  FREE = 'free',
  PREMIUM = 'premium',
  CANCELED = 'canceled',
  PAUSED = 'paused',
  TRIAL = 'trial',
}

export enum Role {
  DEV = 'developer',
  ADMIN = 'admin',
  COACH = 'coach',
  COACH_MANAGER = 'coach_manager',
  THERAPIST = 'therapist',
  PRODUCT = 'product',
  USER = 'user',
  TRUCARE = 'trucare',
}

// IMPORTANT!!!
// do not edit manually, copy/paste from the BE: src/types/Permission
export enum Permission {
  // Developer permissions
  DEV_ALL = 'developer:all',

  // Coaching permissions
  // - view own users
  // - view user actions
  // - read chat messages
  COACHING_READ = 'coaching:read',
  // - create and change action items
  // - write chat messages
  // - upload meeting transcript
  // - toggle show/no-show for a meeting
  COACHING_WRITE = 'coaching:write',

  // Moderation permissions
  // - view all users
  // - view user action triggers
  MODERATION_READ = 'moderation:read',
  // - set manual trial on a user
  // - update Coach and Therapist profiles
  // - toggle rotation on a Coach
  // - switch Coach for a user
  // - suspend a user
  // - delete a user
  // - create, update and delete user action triggers
  MODERATION_WRITE = 'moderation:write',

  // Content permissions
  // - view scheduled notifications
  // - view all company codes
  CONTENT_READ = 'content:read',
  // - manage cbt programs
  // - manage notifications
  // - manage therapy courses
  // - manage news
  CONTENT_WRITE = 'content:write',

  // Therapy permissions
  THERAPY_ALL = 'therapy:all',

  // Administration permissions
  // - view all users
  // - switch roles
  ADMINISTRATION_ALL = 'administration:all',
  ADMINISTRATION_IMPERSONATION = 'administration:impersonation',

  // TRUCARE to access our report files
  TRUCARE_FILES_ALL = 'trucare:all',
}

export const roleSchema = z.nativeEnum(Role);
export const permissionSchema = z.nativeEnum(Permission);

export const paymentMethodSchema = z.nativeEnum(PaymentMethod);
export const subscriptionStatusSchema = z.nativeEnum(SubscriptionStatus);

const licenseSchema = z.object({
  description: z.string(),
  state: z.string(),
});

const coachSchema = z.object({
  tags: z.string().optional(),
  introCallUrl: z.string().optional(),
  weekendWelcomeVideoUrl: z.string().optional(),
  isProd: z.boolean().optional(),
  richTextDescription: z.string().optional(),
  oneLiner: z.string().optional(),
  calendarUrl: z.string().optional(),
  license: z.array(licenseSchema).optional(),
});

export const followupTypeSchema = z.enum(['trial', 'action', 'meeting', 'followup', 'disengaged']);

export const followupBucketSchema = z.object({
  isFollowupRequired: z.boolean().optional(),
  isHavingAction: z.boolean().optional(),
  isMeetingCoachToday: z.boolean().optional(),
  isAwaitingResponse: z.boolean().optional(),
  isDisengaged: z.boolean().optional(),
});

export const userRoleSchema = z.object({
  role: roleSchema.or(z.any()).optional(),
});

// got to duplicate these here because of circular dependency
enum TherapySessionStatus {
  SCHEDULED = 'SCHEDULED',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
}

const therapySchema = z.object({
  id: z.string(),
  userId: z.string(),
  courseId: z.string(),
  therapistId: z.string(),
  channelId: z.string().optional(),
  status: z.string(),
  createdAt: dateSchema,
  updatedAt: dateSchema,
  assessmentSlug: z.string().or(z.null()).optional(),
  course: therapyCourseSchema.optional(),
});

const therapySessionSchema = z.object({
  id: z.string(),
  therapyId: z.string(),
  therapistId: z.string(),
  meetingId: z.string(),
  userId: z.string(),
  status: z.nativeEnum(TherapySessionStatus),
  copayAmount: z.number(),
});

export const therapyClientSchema = z.object({
  userId: z.string(),
  therapistId: z.string(),
  lastTherapySessionDate: dateSchema,
  therapyName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

export const userSchema = z
  .object({
    nickname: z.string().optional(),
    firstName: z.string().or(z.null()).optional(),
    lastName: z.string().or(z.null()).optional(),
    email: z.string(),
    userId: z.string(),
    isCoach: z.boolean(),
    isAdmin: z.boolean().optional(),
    isDev: z.boolean().optional(),
    channelId: z.string().or(z.null()).optional(),
    coachId: z.string().or(z.null()).optional(),
    avatarUrl: z.string().or(z.null()).optional(),
    description: z.string().optional(),
    subscriptionStatus: subscriptionStatusSchema,
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    subscriptionLastPaymentDate: z.string().or(z.null()).optional(),
    isDeleted: z.boolean().optional(),
    trialEndDate: z.string().or(z.null()).optional(),
    notificationsTurnedOn: z.boolean().optional(), // @deprecated
    isNotificationsAllowed: z.boolean().optional(),
    premiumSinceDate: z.string().or(z.null()).optional(),
    canceledSinceDate: z.string().or(z.null()).optional(),
    appVersion: z.string().or(z.null()).optional(),
    timezone: z.string().or(z.null()).optional(),
    coach: coachSchema.optional(),
    phone: z.string().or(z.null()).optional(),
  })
  .merge(coachSchema);

export const userViewSchema = z
  .object({
    gender: z.string().or(z.null()).optional(),
    dateOfBirth: dateSchema,
    lastSeenDate: dateSchema,
    lastCallDate: dateSchema,
    firstCallDate: dateSchema,
    activitiesAmount: z.number().optional(),
    callsScheduledAmount: z.number().optional(),
    appVersion: z.string().or(z.null()).optional(),
    platform: z.string().or(z.null()).optional(),
    device: z.string().nullable().optional(),
    ipAddress: z.string().nullable().optional(),
    paymentMethod: paymentMethodSchema.nullable().optional(),
    isAlpha: z.boolean().optional(),
    isBillingIssue: z.boolean().optional(),
    clientCompany: z.string().nullable().optional(),
    followupBucket: followupBucketSchema.or(z.null()).optional(),
    userRole: userRoleSchema.or(z.null()).optional(),
    role: roleSchema.optional(),
    permissions: z.array(permissionSchema).optional(),
    therapies: z.array(therapySchema).optional(),
    therapySessions: z.array(therapySessionSchema).optional(),
    therapistScheduleUrl: z.string().optional(),
  })
  .merge(userSchema);

export type License = z.infer<typeof licenseSchema>;
export type CoachData = z.infer<typeof coachSchema>;
export type User = z.infer<typeof userSchema>;
export type UserView = z.infer<typeof userViewSchema>;
export type UserData = {
  me: UserView;
  coach?: UserView;
};

type Answer = {
  key?: string;
  value?: string | number | boolean | Date;
  text?: string;
};

export type UserAnswer = {
  questionSlug: string;
  answers: Answer[];
};

export const getUserData = async () => {
  return api(userEndpoint).json<UserData>();
};

export interface UpdateUserFormValues {
  nickname?: string;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  calendarUrl?: string;
  description?: string;
  introCallUrl?: string;
  oneLiner?: string;
  richTextDescription?: string;
  tags?: string;
  timezone?: string;
  weekendWelcomeVideoUrl?: string;
  license?: License[];
}

export const updateUser = async (body: UpdateUserFormValues, userId?: string) => {
  const endpoint = userId ? `user/${userId}` : userEndpoint;
  return api.put(endpoint, { json: body, timeout: 60 * 1000 }).json<UserData>();
};

export type GetUserByIdResponse = {
  user: UserData;
  onboarding?: UserAnswer[];
};
export const getUserById = async (userId: string) => {
  return api(`${userByIdEndpoint}/v2/${userId}`).json<GetUserByIdResponse>();
};

export const toggleRotation = async (userId: string) => {
  return api.put(`user/${userId}/toggle-rotation`).json<User>();
};

export const toggleFollowRequirement = async (userId: string) =>
  api.put(`user/${userId}/toggle-followup-requirement`).json<User>();
